import React from "react";
import { Link } from "gatsby";

import { ANIM_DELAY } from "../../constants/constants";

const TabSection = () => {
  return (
    <section className="tab">
      <div
        className="d-flex flex-column align-items-center"
        data-sal="fade"
        data-sal-delay={ANIM_DELAY}
        data-sal-easing="ease"
      >
        <h2 className="tab__title">Sample text goes here</h2>
        <div className="tab__btnWrapper">
          <Link className=" tab__button tab__button-primary" to="#">
            Get Started
          </Link>
          <Link className="tab__button tab__button-secondary" to="#">
            Talk to an expert
          </Link>
        </div>
      </div>
    </section>
  );
};
export default TabSection;
